export const duplicateFields = {
  item1: { label: '', field: 'first_name' },
  item2: { label: '', field: 'last_name', newline: true, hideComma: true },
  item3: { label: '', field: 'mobile' },
  item4: { label: '', field: 'mobile_2', newline: true },
  item5: { label: '', field: 'email' },
  item6: { label: '', field: 'email_2', newline: true },
  item7: { label: 'PAN No', field: 'pan_no', newline: true },
  item8: { label: 'Primary Account', field: 'primary_company', newline: true },
}
export const sectorData = [
  {
    id: 'AccountancyBankingAndFinance',
    name: 'Accountancy, Banking & Finance',
    sector: 'Accountancy, Banking & Finance',
  },
  { id: 'Aerospace', name: 'Aerospace', sector: 'Aerospace' },
  { id: 'AgriORFood', name: 'Agri/Food', sector: 'Agri/Food' },
  { id: 'Automotive', name: 'Automotive', sector: 'Automotive' },
  { id: 'BioTech', name: 'BioTech', sector: 'BioTech' },

  {
    id: 'BusinessConsultingAndManagement',
    name: 'Business Consulting & Management',
    sector: 'Business Consulting & Management',
  },
  {
    id: 'CharityVoluntaryWorkAndNonProfit',
    name: 'Charity, Voluntary Work & Non-Profit',
    sector: 'Charity, Voluntary Work & Non-Profit',
  },
  {
    id: 'CreativeArtsAndDesign',
    name: 'Creative Arts & Design',
    sector: 'Creative Arts & Design',
  },
  { id: 'Distribution', name: 'Distribution', sector: 'Distribution' },

  // {
  //   id: 'DistributionTransportAndLogistics',
  //   name: 'Distribution, Transport & Logistics',
  // },
  {
    id: 'EducationAndTeacherTraining',
    name: 'Education & Teacher Training',
    sector: 'Education & Teacher Training',
  },
  {
    id: 'EnergyAndUtilities',
    name: 'Energy & Utilities',
    sector: 'Energy & Utilities',
  },
  {
    id: 'EngineeringAndManufacturing',
    name: 'Engineering & Manufacturing',
    sector: 'Engineering & Manufacturing',
  },
  {
    id: 'EnvironmentAgricultureForestry',
    name: 'Environment, Agriculture Forestry',
    sector: 'Environment, Agriculture Forestry',
  },
  { id: 'Fishing', name: 'Fishing', sector: 'Fishing' },

  { id: 'Healthcare', name: 'Healthcare', sector: 'Healthcare' },
  {
    id: 'HospitalityAndEventsManagement',
    name: 'Hospitality & Events Management',
    sector: 'Hospitality & Events Management',
  },
  {
    id: 'InformationTechnology',
    name: 'Information Technology',
    sector: 'Information Technology',
  },
  { id: 'Insurance', name: 'Insurance', sector: 'Insurance' },

  { id: 'Law', name: 'Law', sector: 'Law' },
  {
    id: 'LawEnforcementAndSecurity',
    name: 'Law Enforcement & Security',
    sector: 'Law Enforcement & Security',
  },
  {
    id: 'LeisureSportAndTourism',
    name: 'Leisure, Sport & Tourism',
    sector: 'Leisure, Sport & Tourism',
  },
  {
    id: 'MarketingAdvertisingAndPR',
    name: 'Marketing, Advertising & PR',
    sector: 'Marketing, Advertising & PR',
  },
  {
    id: 'MediaAndInternet',
    name: 'Media & Internet',
    sector: 'Media & Internet',
  },
  {
    id: 'MiningORQuarrying',
    name: 'Mining/Quarrying',
    sector: 'Mining/Quarrying',
  },
  {
    id: 'OtherServiceActivities',
    name: 'Other Service Activities',
    sector: 'Other Service Activities',
  },
  {
    id: 'PropertyAndConstruction',
    name: 'Property & Construction',
    sector: 'Property & Construction',
  },
  {
    id: 'PublicServicesAndAdministration',
    name: 'Public Services & Administration',
    sector: 'Public Services & Administration',
  },
  {
    id: 'RealEstateActivities',
    name: 'Real Estate Activities',
    sector: 'Real Estate Activities',
  },
  {
    id: 'RecruitmentAndHR',
    name: 'Recruitment & HR',
    sector: 'Recruitment & HR',
  },
  {
    id: 'ResearchAndDevelopment',
    name: 'Research & Development',
    sector: 'Research & Development',
  },
  { id: 'Retail', name: 'Retail', sector: 'Retail' },
  {
    id: 'ScienceAndPharmaceuticals',
    name: 'Science & Pharmaceuticals',
    sector: 'Science & Pharmaceuticals',
  },
  { id: 'SocialCare', name: 'Social Care', sector: 'Social Care' },
  // { id: 'TechnologyIncludingBioTech', name: 'Technology, including BioTech' },
  { id: 'Technology', name: 'Technology', sector: 'Technology' },
  {
    id: 'TransportLogistics',
    name: 'Transport & Logistics',
    sector: 'Transport & Logistics',
  },
  { id: 'None', name: 'None', sector: 'None' },
]

export const orgSize = [
  { name: '0', id: '0', label: '0', value: '0' },
  { name: '1 to 50', id: '1 to 50', label: '1 to 50', value: '1 to 50' },
  {
    name: '51 to 250',
    id: '51 to 250',
    label: '51 to 250',
    value: '51 to 250',
  },
  {
    name: '251 to 500',
    id: '251 to 500',
    label: '251 to 500',
    value: '251 to 500',
  },
  { name: '500+', id: '500+', label: '500+', value: '500+' },
]

export const tierData = [
  {
    id: 'Diversity Mark Accreditation',
    tier: 'Diversity Mark Accreditation',
  },
  {
    id: 'Silver Accreditation',
    tier: 'Silver Accreditation',
  },
  {
    id: 'Gold Accreditation',
    tier: 'Gold Accreditation',
  },
]
